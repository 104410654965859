import React, { createContext, useMemo, useContext, useState } from "react";
import { io } from "socket.io-client";

export const SocketContext = createContext(null);

export const SocketProvider = (props) => {
  // const socket = io("http://localhost:9000");
  // const audioSocket = io("http://localhost:9001");

  const socket = io("https://soulmegle.com/", {
    path: "/api/socket.io",
  });
  const audioSocket = io("https://soulmegle.com/", {
    path: "/audioServer/socket.io",
  });

  return (
    <SocketContext.Provider
      value={{
        socket,
        audioSocket,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  );
};
export const useSocket = () => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error("useLogin must be used within a LoginProvider");
  }
  return context;
};
