import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

const LoadingGears = () => {
  return (
    <div className="loading-gears" style={styles.loadingGears}>
      <div style={styles.gear}>
        <FontAwesomeIcon icon={faCog} size="5x" style={styles.largeGear} />
      </div>
      <div style={styles.gear}>
        <FontAwesomeIcon icon={faCog} size="3x" style={styles.smallGear} />
      </div>
    </div>
  );
};

const styles = {
  loadingGears: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "10vh",
    margin: "2vh",
  },
  gear: {
    color: "black",
  },
  largeGear: {
    animation: "rotateGear 3s linear infinite",
  },
  smallGear: {
    animation: "rotateGearReverse 2s linear infinite",
  },
};

export default LoadingGears;
