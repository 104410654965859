import React, { useEffect, useState } from "react";
import "./LoginPage.css"; // Import the CSS file
import "./SignupPage.css";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../../context/LoginProvider";
import { continueWithGoogle, signInWithGoogle, signup } from "../../utils/auth";
import AlertBox from "../../components/AlertBox/AlertBox";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { useSocket } from "../../context/SocketProvider";

function SignupPage() {
  const { setUserData, setLoggedIn, isLoggedIn, guestData, setGuestUserData } =
    useLogin();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [isDisabled, setDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { socket } = useSocket();
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) navigate("/", { replace: true });
  }, [isLoggedIn]);
  const acquireSession = (userData) => {
    return new Promise((resolve, reject) => {
      if (!socket) {
        reject(new Error("Socket not connected"));
        return;
      }

      socket.emit("acquireSession", userData, (response) => {
        if (response.error) {
          reject(new Error(response.error));
        } else {
          resolve(response.user);
        }
      });
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);
    try {
      const data = await signup(
        formData.name,
        formData.email,
        formData.password,
        guestData.username
      );
      console.log(data);
      await acquireSession(data);
      setLoggedIn(true);
      setUserData({
        name: formData.name,
        username: data.username,
        email: formData.email,
        interests: data.interests,
      });
      if (data.interests.length === 0) navigate("/cupid", { replace: true });
      else navigate("/chat", { replace: true });
      // Assuming /cupid is the dashboard or next step
    } catch (error) {
      console.error(error);
      setAlertMessage(error.message);
      setShowAlert(true);
    } finally {
      setDisabled(false);
    }
  };

  async function handleGoogleLoginSuccess(tokenResponse) {
    try {
      const data = await continueWithGoogle(tokenResponse);
      console.log("Sign-In With Google Result:", data);

      await acquireSession(data);
      setLoggedIn(true);
      setUserData({
        name: data.name,
        username: data.username,
        email: data.email,
        interests: data.interests,
      });

      if (data.interests.length === 0) navigate("/cupid", { replace: true });
      else navigate("/chat", { replace: true });
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      setAlertMessage(
        error.message || "An error occurred during Google Sign-In"
      );
      setShowAlert(true);
    }
  }
  const loginWithGoogle = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onError: (error) => {
      console.error("Google Sign-In Error:", error);
      setAlertMessage("Google Sign-In failed. Please try again.");
      setShowAlert(true);
    },
    flow: "implicit",
    ux_mode: "popup",
    use_fedcm_for_prompt: true,
  });

  const closeAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className="login-page">
      <div className="signup-image">{/* Image is added via CSS */}</div>
      <div className="login-form">
        <div className="login-header">
          <h1>Register</h1>
          <p>Welcome to soulmegle</p>
        </div>
        <form onSubmit={handleSubmit}>
          <label className="label-email" htmlFor="login-name">
            Name
          </label>
          <div className="input-container">
            <input
              id="login-name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Type your name..."
              required
            />
          </div>
          <label className="label-email" htmlFor="login-email">
            Email
          </label>
          <div className="input-container">
            <input
              id="login-email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              required
            />
          </div>
          <label className="label-email" htmlFor="login-password">
            Password
          </label>
          <div className="input-container">
            <input
              id="login-password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter your password"
              required
            />
            <button
              className="toggle-password"
              onClick={(e) => {
                e.preventDefault();
                togglePasswordVisibility();
              }}
            >
              {showPassword ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 13C6.6 5 17.4 5 21 13"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 17C11.606 17 11.2159 16.9224 10.8519 16.7716C10.488 16.6209 10.1573 16.3999 9.87868 16.1213C9.6001 15.8427 9.37913 15.512 9.22836 15.1481C9.0776 14.7841 9 14.394 9 14C9 13.606 9.0776 13.2159 9.22836 12.8519C9.37913 12.488 9.6001 12.1573 9.87868 11.8787C10.1573 11.6001 10.488 11.3791 10.8519 11.2284C11.2159 11.0776 11.606 11 12 11C12.7956 11 13.5587 11.3161 14.1213 11.8787C14.6839 12.4413 15 13.2044 15 14C15 14.7956 14.6839 15.5587 14.1213 16.1213C13.5587 16.6839 12.7956 17 12 17Z"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.21998 2.22007C2.09296 2.34706 2.01573 2.51541 2.00233 2.69452C1.98894 2.87363 2.04026 3.0516 2.14698 3.19607L2.21998 3.28007L6.25398 7.31507C4.28791 8.6946 2.88402 10.7356 2.29898 13.0651C2.25396 13.2569 2.28621 13.4587 2.38878 13.6269C2.49135 13.7951 2.65599 13.9162 2.84711 13.964C3.03824 14.0118 3.24051 13.9825 3.4102 13.8824C3.57989 13.7823 3.70338 13.6195 3.75398 13.4291C4.27378 11.3615 5.55157 9.56474 7.33398 8.39507L9.14398 10.2051C8.42097 10.9606 8.02257 11.9692 8.0341 13.0148C8.04563 14.0604 8.46619 15.06 9.20568 15.7994C9.94517 16.5387 10.9448 16.9591 11.9904 16.9704C13.0361 16.9818 14.0446 16.5832 14.8 15.8601L20.719 21.7801C20.8528 21.9143 21.0325 21.9928 21.2219 21.9999C21.4112 22.0071 21.5963 21.9422 21.7398 21.8185C21.8833 21.6947 21.9747 21.5212 21.9955 21.3328C22.0163 21.1444 21.965 20.9552 21.852 20.8031L21.779 20.7191L15.666 14.6051L15.667 14.6031L14.467 13.4051L11.597 10.5351H11.599L8.71898 7.65807L8.71998 7.65607L7.58698 6.52607L3.27998 2.22007C3.13935 2.07962 2.94873 2.00073 2.74998 2.00073C2.55123 2.00073 2.3606 2.07962 2.21998 2.22007ZM10.204 11.2651L13.739 14.8011C13.2675 15.2565 12.636 15.5085 11.9805 15.5028C11.325 15.4971 10.6979 15.2341 10.2344 14.7706C9.77091 14.3071 9.50799 13.6801 9.50229 13.0246C9.4966 12.3691 9.74858 11.7366 10.204 11.2651ZM12 5.50007C11 5.50007 10.03 5.64807 9.11098 5.92507L10.348 7.16107C12.4875 6.73743 14.7081 7.15215 16.5505 8.3195C18.3929 9.48685 19.7162 11.3175 20.247 13.4331C20.2988 13.622 20.4225 13.7832 20.5916 13.8821C20.7607 13.981 20.9617 14.0098 21.1518 13.9624C21.3419 13.915 21.5059 13.7951 21.6087 13.6283C21.7115 13.4616 21.745 13.2612 21.702 13.0701C21.1602 10.9073 19.9111 8.98758 18.1533 7.61601C16.3954 6.24444 14.2296 5.49969 12 5.50007ZM12.195 9.01007L15.996 12.8101C15.9468 11.818 15.5305 10.8796 14.8281 10.1773C14.1256 9.47501 13.1871 9.05898 12.195 9.01007Z"
                    fill="black"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className="submit-container">
            <button type="submit" disabled={isDisabled}>
              Register
            </button>
          </div>
          <div className="signup-link">
            Already have an account!{" "}
            <button onClick={() => navigate("/login", { replace: true })}>
              Login
            </button>
          </div>
        </form>

        <div className="separator">
          <div></div>
          <span>Or</span>
          <div></div>
        </div>
        <button className="guest-button" onClick={loginWithGoogle}>
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.3055 10.5415H21.5V10.5H12.5V14.5H18.1515C17.327 16.8285 15.1115 18.5 12.5 18.5C9.1865 18.5 6.5 15.8135 6.5 12.5C6.5 9.1865 9.1865 6.5 12.5 6.5C14.0295 6.5 15.421 7.077 16.4805 8.0195L19.309 5.191C17.523 3.5265 15.134 2.5 12.5 2.5C6.9775 2.5 2.5 6.9775 2.5 12.5C2.5 18.0225 6.9775 22.5 12.5 22.5C18.0225 22.5 22.5 18.0225 22.5 12.5C22.5 11.8295 22.431 11.175 22.3055 10.5415Z"
              fill="#FFC107"
            />
            <path
              d="M3.65308 7.8455L6.93858 10.255C7.82758 8.054 9.98058 6.5 12.5001 6.5C14.0296 6.5 15.4211 7.077 16.4806 8.0195L19.3091 5.191C17.5231 3.5265 15.1341 2.5 12.5001 2.5C8.65908 2.5 5.32808 4.6685 3.65308 7.8455Z"
              fill="#FF3D00"
            />
            <path
              d="M12.4999 22.4999C15.0829 22.4999 17.4299 21.5114 19.2044 19.9039L16.1094 17.2849C15.0717 18.074 13.8036 18.5009 12.4999 18.4999C9.89891 18.4999 7.69041 16.8414 6.85841 14.5269L3.59741 17.0394C5.25241 20.2779 8.61341 22.4999 12.4999 22.4999Z"
              fill="#4CAF50"
            />
            <path
              d="M22.3055 10.5415H21.5V10.5H12.5V14.5H18.1515C17.7571 15.6082 17.0467 16.5766 16.108 17.2855L16.1095 17.2845L19.2045 19.9035C18.9855 20.1025 22.5 17.5 22.5 12.5C22.5 11.8295 22.431 11.175 22.3055 10.5415Z"
              fill="#1976D2"
            />
          </svg>
          Continue with google
        </button>
        {/* <div className="google-container">
          <GoogleLogin
            onSuccess={handleGoogleSignin}
            onError={(error) => {
              setAlertMessage(error);
              setShowAlert(true);
            }}
          />
        </div> */}
      </div>
      {showAlert && (
        <AlertBox
          message={alertMessage}
          onClose={closeAlert}
          success="failure"
        />
      )}
    </div>
  );
}

export default SignupPage;
