import { useNavigate } from "react-router-dom";
import UserMenu from "./UserMenu/UserMenu";
import navHeader from "../assets/navHeader.svg";
export default function Navbar({ isHomePage }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="navbar">
        <img
          src={navHeader}
          onClick={() => {
            navigate("/");
          }}
        />
        <UserMenu isHomePage={isHomePage} />
      </div>
    </>
  );
}
