import AvatarSignUp from "./SignupAvatar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import WelcomeCard from "../../components/AlertBox/WelcomeCard";
import { useLogin } from "../../context/LoginProvider";
export default function ProtectedSignup() {
  const { isLoggedIn, userData, guestData } = useLogin();
  const [isCard, setShowCard] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      (isLoggedIn && userData.interests.length !== 0) ||
      (!isLoggedIn && guestData.interests.length !== 0)
    ) {
      navigate("/chat", { replace: true });
    }
  }, [isLoggedIn, userData, guestData]);
  return (
    <>
      {" "}
      {isCard ? (
        <WelcomeCard
          onClose={() => {
            setShowCard(!isCard);
          }}
        />
      ) : (
        <AvatarSignUp />
      )}
    </>
  );
}
