import "./AlertBox.css";
import BotImage from "../../assets/Bot.png";
import LineImage from "../../assets/line.png";
import { useEffect, useState } from "react";
export default function WelcomeCard({ onClose }) {
  const fullText =
    "oll revolutionizes connections by matching users based on shared interests and goals, whether for friendship, networking, or romance.";
  const [text, setText] = useState("S");
  useEffect(() => {
    let index = 1;
    const timer = setInterval(() => {
      if (index < fullText.length) {
        setText((prev) => prev + fullText[index - 1]);

        index++;
      } else {
        clearInterval(timer);
      }
    }, 50); // Adjust the speed of typing here (in milliseconds)

    return () => clearInterval(timer);
  }, []);
  return (
    <div className="alert-overlay">
      <div className="alert-modal">
        <img src={BotImage} className="alert-image" />
        <img src={LineImage} className="alert-image" />

        <p className="love">{text}</p>
        <button className="alert-modal-button" onClick={onClose}>
          Grant Speaker Access
        </button>
      </div>
    </div>
  );
}
