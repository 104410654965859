import { useNavigate } from "react-router-dom";
import LoadingGears from "../Stylings/LoadingGears";
import "./AlertBox.css";
export default function LoadingBox() {
  const navigate = useNavigate();
  return (
    <div className="alert-overlay">
      <div className="alert-modal">
        <p className="success">Detecting Interests...</p>
        <LoadingGears />
        <p>
          Tip - Enter your interests before visting chats by going{" "}
          <button
            className="buttonPurple"
            onClick={() => {
              navigate("/login");
            }}
          >
            here
          </button>
        </p>
      </div>
    </div>
  );
}
