import { BrowserRouter, Routes, Route } from "react-router-dom";

import { useEffect, useState } from "react";
import ChatPage from "./components/ChatPage";

import { useSocket } from "./context/SocketProvider";
import Homepage from "./pages/Homepage";
import LoginPage from "./pages/Authentication/LoginPage";
import SignupPage from "./pages/Authentication/SignupPage";
import { useLogin } from "./context/LoginProvider";

import ProtectedSignup from "./pages/Authentication/ProtectedSignup";

import ForgotPassword from "./pages/Authentication/ForgotPassword";
import { useEmbeddings } from "./context/EmbeddingsProvider";

export default function Home() {
  const { socket, audioSocket } = useSocket();
  const {
    isLoggedIn,
    userData,
    guestData,
    setGuestUserData,
    setUserData,
    logoutUser,
    setLoggedIn,
  } = useLogin();

  const [isJoined, setisJoined] = useState(false);
  const [roomId, setroomId] = useState("");
  const { embeddings, setEmbeddings, isEmbeddingsLoaded, setEmbeddingsLoaded } =
    useEmbeddings();

  const [token, setToken] = useState(null);
  const acquireSession = (userData) => {
    return new Promise((resolve, reject) => {
      if (!socket) {
        reject(new Error("Socket not connected"));
        return;
      }

      socket.emit("acquireSession", userData, (response) => {
        if (response.error) {
          reject(new Error(response.error));
        } else {
          resolve(response.user);
        }
      });
    });
  };
  useEffect(() => {
    async function attemptLogin() {
      const loggedInUser = localStorage.getItem("user");
      try {
        if (loggedInUser) {
          const foundUser = JSON.parse(loggedInUser);
          await acquireSession(foundUser);
          setUserData(foundUser);
          setLoggedIn(true);
        }
      } catch (e) {
        console.log("Cant acquire session" + e);
      }
    }
    attemptLogin();

    if (guestData.username === "") {
      const RandomNumber = Math.floor(
        100000 + Math.random() * 900000
      ).toString();
      let newUsername = "guest" + Date.now() + RandomNumber;
      setGuestUserData({ username: newUsername, interests: [] });
    }
  }, []);

  useEffect(() => {
    if (embeddings.length > 0) {
      setEmbeddingsLoaded(true);
    }
  }, [embeddings]);

  useEffect(() => {
    if (userData.interests.length !== 0) {
      socket.emit("getEmbeddings", { interests: userData.interests });
    }
  }, [userData]);
  useEffect(() => {
    if (guestData.interests.length !== 0) {
      socket.emit("getEmbeddings", { interests: guestData.interests });
    }
  }, [guestData]);

  const handleForceLogout = () => {
    logoutUser();
  };
  const handlePostEmbeddings = (data) => {
    setEmbeddings(data.embeddings);
  };
  const handlePostInterests = (data) => {
    if (isLoggedIn != data.isLoggedIn) return;
    if (isLoggedIn && userData.username !== data.username) return;
    if (!isLoggedIn && guestData.username !== data.username) return;
    if (isLoggedIn)
      setUserData((prevUserData) => ({
        ...prevUserData,
        interests: data.updatedInterest,
      }));
    if (!isLoggedIn)
      setGuestUserData((prevUserData) => ({
        ...prevUserData,
        interests: data.updatedInterest,
      }));
  };

  //Hook to set embedding on socket call
  useEffect(() => {
    if (socket === null) return;

    socket.on("forceLogout", handleForceLogout);
    socket.on("postEmbeddings", handlePostEmbeddings);
    socket.on("postInterests", handlePostInterests);
    return () => {
      socket.off("forceLogout", handleForceLogout);
      socket.off("postEmbeddings", handlePostEmbeddings);
      socket.off("postInterests", handlePostInterests);
    };
  }, [socket]);

  useEffect(() => {
    if (audioSocket === null) return;

    audioSocket.on("forceLogout", handleForceLogout);
    audioSocket.on("postEmbeddings", handlePostEmbeddings);
    audioSocket.on("postInterests", handlePostInterests);
    return () => {
      audioSocket.off("forceLogout", handleForceLogout);
      audioSocket.off("postEmbeddings", handlePostEmbeddings);
      audioSocket.off("postInterests", handlePostInterests);
    };
  }, [audioSocket]);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/login" element={<LoginPage setToken={setToken} />} />
          <Route path="/signup" element={<SignupPage setToken={setToken} />} />
          <Route
            path="/chat"
            element={
              <ChatPage
                roomId={roomId}
                setroomId={setroomId}
                isJoined={isJoined}
                setisJoined={setisJoined}
              />
            }
          />
          <Route path="/cupid" element={<ProtectedSignup />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
