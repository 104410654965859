import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useLogin } from "../context/LoginProvider";

export default function Homepage() {
  const navigate = useNavigate();
  const { isLoggedIn } = useLogin();
  return (
    <>
      <Navbar isHomePage={false} />
      <div className="page">
        <div className="homeContent">
          <div className="handSign"></div>
          <div>
            <h1 className="light-font">Discover Your</h1>
            <h1 className="bold-font"> Perfect Match</h1>

            <p>Join our community to connect with your soulmate today.</p>

            <button
              className="buttonNormal"
              onClick={() => {
                if (isLoggedIn) navigate("/chat");
                else navigate("/login");
              }}
            >
              {isLoggedIn ? "Start Matching" : "Get Started"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
