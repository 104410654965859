// Import React dependencies
import React, { createContext, useContext, useState } from "react";

// Define the initial state

// Create the context
const ConnectionContext = createContext({
  soulmateUsername: "",
  setSoulmateUsername: () => {}, // This will be overridden by the provider
});

export const ConnectionProvider = ({ children }) => {
  const [soulmateUsername, setSoulmateUsername] = useState("");
  const [soulmateStatus, setSoulmateStatus] = useState(false);

  return (
    <ConnectionContext.Provider
      value={{
        soulmateUsername,
        setSoulmateUsername,
        soulmateStatus,
        setSoulmateStatus,
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};
// Hook to use the login context
export const useConnection = () => {
  const context = useContext(ConnectionContext);
  if (context === undefined) {
    throw new Error("useLogin must be used within a LoginProvider");
  }
  return context;
};
