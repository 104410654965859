import React, { useState, useEffect, useRef, useCallback } from "react";
import "./testChat.css";

import { useConnection } from "../../context/SoulmateProvider";
import { useSocket } from "../../context/SocketProvider";
import { useLogin } from "../../context/LoginProvider";

export default function TestChat({ roomId, isJoined }) {
  // const { soulmateUsername, soulmateStatus } = useConnection();
  const { socket, audioSocket } = useSocket();
  const { userData, isLoggedIn, guestData } = useLogin();
  const [message, setMessage] = useState(""); // Message input state
  const [messages, setMessages] = useState([]);
  const [isdisabled, setDisabled] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  //use effect to scroll to bottom when new message arrives
  useEffect(() => {
    scrollToBottom();
  }, [messages, suggestions]);

  //useEffect to update interests
  useEffect(() => {
    setDisabled(!isJoined);

    if (messages.length !== 0) {
      audioSocket.emit("update-interests", {
        username: isLoggedIn ? userData.username : guestData.username,
        messages: messages,
        isLoggedIn: isLoggedIn,
      });
    }
    setSuggestions([]);
    setMessages([]);
  }, [isJoined]);

  //useEffect to update suggestions

  // useEffect(() => {
  //   if (!isJoined || messages.length === 0) return;
  //   audioSocket.emit("updateSuggestions", {
  //     soulmateUsername,
  //     messages,
  //     soulmateStatus,
  //   });
  // }, [messages]);

  // Array to store received messages
  const handleMessage = (data) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { isUser: false, isSol: false, message: data.message },
    ]);
  };

  const handleStarters = useCallback(
    (data) => {
      if (messages.length === 0) {
        setMessages([
          { isUser: false, isSol: true, message: data.starters[0] },
        ]);
      }
      setSuggestions(data.starters);
    },
    [messages]
  );

  //UseEffect for sockets
  useEffect(() => {
    socket.on("message", handleMessage);
    socket.on("starters", handleStarters);

    return () => {
      socket.off("message", handleMessage);
      socket.off("starters", handleStarters);
    };
  }, [socket, messages]);
  useEffect(() => {
    audioSocket.on("starters", handleStarters);

    return () => {
      audioSocket.off("starters", handleStarters);
    };
  }, [audioSocket, messages]);

  const sendMessage = (event) => {
    event.preventDefault();
    if (message === "") return;
    // Emit a 'message' event with the recipient ID and message content
    socket.emit("message", { roomId, message });
    setMessages((prevMessages) => [
      ...prevMessages,
      { isUser: true, isSol: false, message: message },
    ]);

    // Clear the message input
    setMessage("");
  };
  function handleClickOnStarter({ suggestion, index }) {
    socket.emit("message", { roomId, message: suggestion });
    setMessages((prevMessages) => [
      ...prevMessages,
      { isUser: true, message: suggestion },
    ]);
    var newSuggestions = suggestions;
    newSuggestions.splice(index, 1);
    setSuggestions(newSuggestions);
  }
  const handleKeyDown = (event) => {
    // Check if the Enter key is pressed without the Shift key
    if (message === "") return;
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent the default action (prevent form submission or newline)
      sendMessage(event);
    }
  };
  return (
    <div className="chat-container">
      <div className="chat-header">
        <div>Chatroom</div>
        {isJoined ? (
          <div className="connectionStatusContainerLite">
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="16" height="16" rx="8" fill="#4CAF50" />
            </svg>
            <span className="connectionStatusTextLite">Connected</span>
          </div>
        ) : (
          <div className="connectionStatusContainerLite">
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="16" height="16" rx="8" fill="#FFC107" />
            </svg>
            <span className="connectionStatusTextLite">Not Connected</span>
          </div>
        )}
      </div>
      {/* <div className="chat-suggestions">
        <div className="suggestion-header">Suggestions</div>
        {suggestions.length > 0 ? (
          suggestions.map((suggestion, index) => (
            <button
              key={index}
              className="suggestion-button"
              onClick={() => handleClickOnStarter({ suggestion, index })}
            >
              {suggestion}
            </button>
          ))
        ) : (
          <div>Loading...</div>
        )}
      </div> */}
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={msg.isUser ? "rightAttached" : "leftAttached"}
          >
            {msg.isUser ? "You :- " : msg.isSol ? "Sol :- " : "Stranger :- "}{" "}
            {msg.message}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input-container">
        <input
          type=""
          placeholder="Type your message here"
          className="chat-input"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={!isJoined}
        />

        <button
          onClick={sendMessage}
          disabled={isdisabled}
          className="send-button"
        >
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.235 2.68597C18.667 1.49097 17.509 0.332968 16.314 0.765968L1.70904 6.04797C0.510042 6.48197 0.365042 8.11797 1.46804 8.75697L6.13004 11.456L10.293 7.29297C10.4816 7.11081 10.7342 7.01002 10.9964 7.01229C11.2586 7.01457 11.5095 7.11974 11.6949 7.30515C11.8803 7.49056 11.9854 7.74137 11.9877 8.00357C11.99 8.26576 11.8892 8.51837 11.707 8.70697L7.54404 12.87L10.244 17.532C10.882 18.635 12.518 18.489 12.952 17.291L18.235 2.68597Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
