import React, { createContext, useState, useContext } from "react";

// Create the context
const EmbeddingsContext = createContext();

// Provider component
export const EmbeddingsProvider = ({ children }) => {
  const [embeddings, setEmbeddings] = useState([]);
  const [isEmbeddingsLoaded, setEmbeddingsLoaded] = useState(false);

  const value = {
    embeddings,
    setEmbeddings,
    isEmbeddingsLoaded,
    setEmbeddingsLoaded,
  };

  return (
    <EmbeddingsContext.Provider value={value}>
      {children}
    </EmbeddingsContext.Provider>
  );
};

// Hook for consuming context
export const useEmbeddings = () => useContext(EmbeddingsContext);
