// Import React dependencies
import React, { createContext, useContext, useEffect, useState } from "react";

// Define the initial state
const initialState = {
  name: "",
  username: "",
  email: "",
  interests: [],
};

// Create the context
const LoginContext = createContext({
  userData: initialState,
  setUserData: () => {}, // This will be overridden by the provider
});

export const LoginProvider = ({ children }) => {
  const [userData, setUserData] = useState(initialState);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [guestData, setGuestUserData] = useState({
    username: "",
    interests: [],
  });

  useEffect(() => {
    if (userData.username !== "")
      localStorage.setItem("user", JSON.stringify(userData));
  }, [userData]);
  const logoutUser = () => {
    setLoggedIn(false);
    setUserData(initialState);
    localStorage.removeItem("user");
  };
  return (
    <LoginContext.Provider
      value={{
        userData,
        setUserData,
        isLoggedIn,
        setLoggedIn,
        guestData,
        setGuestUserData,
        logoutUser,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
// Hook to use the login context
export const useLogin = () => {
  const context = useContext(LoginContext);
  if (context === undefined) {
    throw new Error("useLogin must be used within a LoginProvider");
  }
  return context;
};
