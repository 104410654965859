import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertBox from "../../components/AlertBox/AlertBox";
import "./LoginPage.css";
import { requestOTPfromServer, resetPasswordtoServer } from "../../utils/auth";
function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const navigate = useNavigate();
  const [isOtpPage, setOtpPage] = useState(true);
  const [globalEmail, setGlobalEmail] = useState("");
  const [isOtpDisabled, setOtpDisabled] = useState(false);
  const [isResetDisabled, setResetDisabled] = useState(false);
  
  const requestOtp = async () => {
    setOtpDisabled(true);
    try {
      let temp = await requestOTPfromServer(email);
      setAlertMessage(temp);
      setAlertType("success");
      setShowAlert(true);
      setOtpPage(!isOtpPage);
      setGlobalEmail(email);
      setOtpDisabled(false);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("failure");
      setShowAlert(true);
      setOtpDisabled(false);
    }
  };

  const resetPassword = async () => {
    setResetDisabled(true);
    try {
      let temp = await resetPasswordtoServer({
        email: globalEmail,
        otp: otp,
        newPassword: newPassword,
      });
      setAlertMessage(temp);
      setAlertType("success");
      setShowAlert(true);
      setOtpPage(!isOtpPage);
      setResetDisabled(false);
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("failure");
      setShowAlert(true);
      setResetDisabled(false);
    }
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className="login-page">
      <div className="login-form">
        <div className="login-header">
          <h1>Reset Password</h1>
          {isOtpPage ? (
            <p>Please enter your email to receive a password reset code.</p>
          ) : (
            <p>
              The Otp has been sent to your email. Please enter the Otp and new
              Password.
            </p>
          )}
        </div>
        {isOtpPage ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              requestOtp();
            }}
          >
            <div className="input-container">
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="submit-container">
              <button type="submit" disabled={isOtpDisabled}>
                Send OTP
              </button>
            </div>
          </form>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              resetPassword();
            }}
          >
            <div className="input-container margin-top-2vh">
              <input
                type="number"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
                required
              />
            </div>
            <div className="input-container">
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
              />
            </div>
            <div className="submit-container">
              <button type="submit" disabled={isResetDisabled}>
                Reset Password
              </button>
            </div>
          </form>
        )}

        <div className="signup-link">
          <button onClick={() => navigate("/login")}>Back to Login</button>
        </div>

        {showAlert && (
          <AlertBox
            message={alertMessage}
            onClose={closeAlert}
            success={alertType}
          />
        )}
      </div>
      <div className="login-image">{/* Image is added via CSS */}</div>
    </div>
  );
}

export default ForgotPassword;
