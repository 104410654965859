// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-menu-toggle {
  min-width: 10vw;
  padding: 8px 16px;
  border-radius: 10px;
  border: 2px solid #ccc;
  font-family: "Syne", sans-serif;
  cursor: pointer;
  background-color: white;
  outline: none;
  margin-right: 2vw;
}

.user-menu {
  position: relative;
  display: inline-block;
  margin-right: 2vw;
}

.menu-list {
  position: absolute;
  z-index: 1;
  list-style: none;
  margin-right: 2vw;
  padding: 0;
  background-color: #fff;

  border-radius: 10px;
  min-width: 10vw; /* Adjust if needed */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.menu-item {
  padding: 8px 16px;
  cursor: pointer;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

.menu-item:hover {
  background-color: rgb(60, 0, 255);
  color: white;
}
.logout-button {
  background: white;
  border: none;
}
.login-button {
  background: white;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/UserMenu/UserMenu.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,sBAAsB;EACtB,+BAA+B;EAC/B,eAAe;EACf,uBAAuB;EACvB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,gBAAgB;EAChB,iBAAiB;EACjB,UAAU;EACV,sBAAsB;;EAEtB,mBAAmB;EACnB,eAAe,EAAE,qBAAqB;EACtC,yCAAyC;AAC3C;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,6BAA6B;AAC/B;;AAEA;EACE,iCAAiC;EACjC,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".user-menu-toggle {\n  min-width: 10vw;\n  padding: 8px 16px;\n  border-radius: 10px;\n  border: 2px solid #ccc;\n  font-family: \"Syne\", sans-serif;\n  cursor: pointer;\n  background-color: white;\n  outline: none;\n  margin-right: 2vw;\n}\n\n.user-menu {\n  position: relative;\n  display: inline-block;\n  margin-right: 2vw;\n}\n\n.menu-list {\n  position: absolute;\n  z-index: 1;\n  list-style: none;\n  margin-right: 2vw;\n  padding: 0;\n  background-color: #fff;\n\n  border-radius: 10px;\n  min-width: 10vw; /* Adjust if needed */\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\n}\n\n.menu-item {\n  padding: 8px 16px;\n  cursor: pointer;\n  text-align: center;\n  border-bottom: 1px solid #ccc;\n}\n\n.menu-item:hover {\n  background-color: rgb(60, 0, 255);\n  color: white;\n}\n.logout-button {\n  background: white;\n  border: none;\n}\n.login-button {\n  background: white;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
