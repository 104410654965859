import "../App.css";
import ConnectionComponent from "./Chat/ConnectionComponent";
import Navbar from "./Navbar";
import TestChat from "./testChat/testChat";
import { useEmbeddings } from "../context/EmbeddingsProvider";
import LoadingBox from "./AlertBox/LoadingBox";
import { useLogin } from "../context/LoginProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export default function ChatPage({ roomId, setroomId, isJoined, setisJoined }) {
  const { isEmbeddingsLoaded } = useEmbeddings();
  const { isLoggedIn, userData, guestData } = useLogin();
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoggedIn && userData.interests.length === 0) {
        navigate("/cupid");
      } else if (!isLoggedIn && guestData.interests.length === 0) {
        navigate("/cupid");
      }
    }, 5000); // Delay of 5 seconds

    return () => clearTimeout(timer);
  }, [isLoggedIn, userData, guestData, navigate]);

  return (
    <>
      <Navbar />
      {isEmbeddingsLoaded &&
      ((isLoggedIn && userData.interests.length !== 0) ||
        (!isLoggedIn && guestData.interests.length !== 0)) ? (
        <div className="BoxPage">
          <div className="BoxpageContent">
            <ConnectionComponent
              setroomId={setroomId}
              setisJoined={setisJoined}
              isJoined={isJoined}
            />
            <TestChat roomId={roomId} isJoined={isJoined} />
          </div>
        </div>
      ) : (
        <LoadingBox />
      )}
    </>
  );
}
