import React, { useRef, useEffect, useState, useCallback } from "react";
import { useSocket } from "../../context/SocketProvider";
import { useLogin } from "../../context/LoginProvider";
import { useNavigate } from "react-router-dom";
import introAudio from "../../assets/sol-intro.mp3";
import endAudio from "../../assets/sol-end.mp3";
import "./SignupAvatar.css";
import Navbar from "../../components/Navbar";
import AlertBox from "../../components/AlertBox/AlertBox";

const AvatarSignUp = () => {
  const [isRecording, setIsRecording] = useState(false);

  const audioContext = useRef(null);
  const scriptProcessor = useRef(null);
  const audioInput = useRef(null);
  const audioChunks = useRef([]);
  const [isBegin, setBegin] = useState(false);
  const [isAudioBar, setAudioBar] = useState(false);
  const [isStop, setStop] = useState(false);
  const [isRetry, setRetry] = useState(false);
  const [isBeginDisabled, setBeginDisabled] = useState(false);
  const [isStopDisabled, setStopDisabled] = useState(false);
  const [isRetryDisabled, setRetryDisabled] = useState(false);
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const { socket, audioSocket } = useSocket();
  const { userData, setUserData, isLoggedIn, guestData, setGuestUserData } =
    useLogin();
  const [initial, setInitial] = useState(true);
  const [play, setPlay] = useState(true);
  const navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [backgroundClass, setBackgroundClass] = useState(
    "default-cupid-background"
  );
  const audioRef = useRef(null);
  const [audioSrc, setAudioSrc] = useState(introAudio);
  const [transcription, setTranscription] = useState("");
  const transcriptionId = useRef("");
  const mediaStream = useRef();
  const sendInterval = useRef();

  function handleTranscription({ data, tid }) {
    if (transcriptionId.current !== tid) return;
    console.log("live transcription arrived: " + data);

    setTranscription((prevTranscription) => prevTranscription + " " + data);
  }
  useEffect(() => {
    setPlay(!play);
    return () => {
      try {
        audioContext.current.close();
        scriptProcessor.current.disconnect();
        audioInput.current.disconnect();
      } catch (e) {
        console.log(e);
      }
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      setIsAnimating(true);
      audioRef.current.play();
    }
  }, [play]);

  const handleInterestResponse = useCallback(
    ({ interests }) => {
      if (interests.length === 0) {
        setAlertMessage("No relevant interests found!");
        setShowAlert(true);
      } else if (isLoggedIn) {
        setUserData((prevUserData) => ({ ...prevUserData, interests }));
        navigate("/chat", { replace: true });
      } else {
        setGuestUserData((prevUserData) => ({ ...prevUserData, interests }));
        navigate("/chat", { replace: true });
      }
      setSubmitDisabled(false);
    },
    [isLoggedIn, navigate, setUserData, setGuestUserData]
  );

  useEffect(() => {
    socket.on("transcription", handleTranscription);
    socket.on("interestResponse", handleInterestResponse);
    return () => {
      socket.off("transcription", handleTranscription);
      socket.off("interestResponse", handleInterestResponse);
    };
  }, [socket]);
  useEffect(() => {
    audioSocket.on("transcription", handleTranscription);
    audioSocket.on("interestResponse", handleInterestResponse);
    return () => {
      audioSocket.off("transcription", handleTranscription);
      audioSocket.off("interestResponse", handleInterestResponse);
    };
  }, [audioSocket]);

  const sendAudio = () => {
    if (audioChunks.current.length > 0) {
      const concatenatedBuffer = audioChunks.current.reduce((acc, buffer) => {
        const tmp = new Uint8Array(acc.byteLength + buffer.byteLength);
        tmp.set(new Uint8Array(acc), 0);
        tmp.set(new Uint8Array(buffer), acc.byteLength);
        return tmp.buffer;
      }, new ArrayBuffer(0));
      audioChunks.current = [];
      audioSocket.emit("TranscribeAudioMessage", {
        tid: transcriptionId.current,
        audio: concatenatedBuffer,
      });

      // Clear the chunks after sending
    }
  };
  const startRecording = async () => {
    setBeginDisabled(true);
    setTranscription("");
    const RandomNumber = Math.floor(100000 + Math.random() * 900000).toString();
    transcriptionId.current = Date.now() + RandomNumber;

    console.log("got new tid" + transcriptionId.current);
    if (backgroundClass !== "alternate-cupid-background")
      setBackgroundClass("alternate-cupid-background");
    setRetryDisabled(true);

    try {
      mediaStream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      audioContext.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      audioInput.current = audioContext.current.createMediaStreamSource(
        mediaStream.current
      );
      scriptProcessor.current = audioContext.current.createScriptProcessor(
        4096,
        1,
        1
      );

      scriptProcessor.current.onaudioprocess = (audioProcessingEvent) => {
        const inputBuffer = audioProcessingEvent.inputBuffer;
        const inputData = inputBuffer.getChannelData(0);
        const pcmData = new Int16Array(inputData.length);
        for (let i = 0; i < inputData.length; i++) {
          pcmData[i] = Math.max(-1, Math.min(1, inputData[i])) * 0x7fff;
        }

        audioChunks.current.push(pcmData.buffer);
      };

      audioInput.current.connect(scriptProcessor.current);
      scriptProcessor.current.connect(audioContext.current.destination);

      setIsRecording(true);
      setAudioBar(true);
      setRetry(false);
      setStop(true);
      setBegin(false);
      setBeginDisabled(false);
      setRetryDisabled(false);
      sendInterval.current = setInterval(sendAudio, 10000);
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setBeginDisabled(false);
      setRetryDisabled(false);
    }
  };

  const stopRecording = () => {
    setStopDisabled(true);
    setAudioBar(false);
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => track.stop());
    }
    if (sendInterval.current) {
      clearInterval(sendInterval.current);
      sendInterval.current = null;
    }
    sendAudio(); // Send any remaining audio data

    scriptProcessor.current.disconnect();
    audioContext.current.suspend().then(() => {
      setIsRecording(false);
    });
    audioInput.current.disconnect();

    setIsAnimating(true);
    setAudioSrc(endAudio);
    setPlay(!play);
    setStop(false);
    setStopDisabled(false);
    setIsRecording(false);
  };

  const handleSubmit = () => {
    setSubmitDisabled(true);

    audioSocket.emit("UpdateInterestsFromText", {
      data: transcription,
      username: isLoggedIn ? userData.username : guestData.username,
      isLoggedIn: isLoggedIn,
    });
  };
  const handleSkipAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = audioRef.current.duration;
    }
    handleAudioEnd();
  };
  const handleAudioEnd = () => {
    if (initial) {
      setBegin(true);
      setInitial(false);
    } else {
      setRetry(true);
    }
    setIsAnimating(false);
  };

  const closeAlert = () => setShowAlert(false);

  return (
    <>
      <div className={"cupid-container " + backgroundClass}>
        <Navbar />
        <div className="flexbox-column height-80vh width-100vw flexbox-row-sm">
          <div className="flexbox-row width-40-100vw height-20vh-sm padding-5-0vh-top">
            <div className="greeting fade-up">
              Hello {isLoggedIn ? userData.name : "Guest User"},
            </div>
            {isBegin && (
              <div className="begin-message fade-up">
                Click on Begin and tell Sol your interests.
              </div>
            )}
            {isStop && (
              <div className="stop-message fade-up">
                Click on Stop when you are done.
              </div>
            )}
            {isRetry && (
              <div className="retry-message fade-up">
                Click on Submit to find people that match your interest or click
                retry to try again.
              </div>
            )}
            <audio ref={audioRef} src={audioSrc} onEnded={handleAudioEnd} />
          </div>
          <div className="center-flexbox height-80-20vh width-20-100vw cupid-background"></div>
          <div className="additional-content">
            <div className="begin-flexbox height-50vh width-40-100vw padding-top-5vh">
              {isRetry && (
                <>
                  <button
                    onClick={startRecording}
                    disabled={isRetryDisabled}
                    className="retry-button fade-up"
                  >
                    Retry
                  </button>
                  <button
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled}
                    className="submit-button fade-up"
                  >
                    Submit
                  </button>
                </>
              )}
              {isStop && (
                <button
                  onClick={stopRecording}
                  disabled={isStopDisabled}
                  className="stop-button fade-up"
                >
                  Stop
                </button>
              )}
              {isAnimating && (
                <button
                  onClick={handleSkipAudio}
                  className="stop-button fade-up white-border-button"
                >
                  Skip Audio
                </button>
              )}
            </div>
            <div className="center-flexbox height-30vh">
              {isBegin && (
                <button
                  onClick={startRecording}
                  disabled={isBeginDisabled}
                  className="begin-button fade-up"
                >
                  Begin
                </button>
              )}
              {isAudioBar && (
                <div className="padding-2vh border-2px-solid-black">
                  <div className="audio-bar fade-up"></div>
                </div>
              )}
              {isAnimating && (
                <div className="padding-2vh border-2px-solid-black">
                  <div className="speaker-bar"></div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showAlert && (
          <AlertBox
            message={alertMessage}
            onClose={closeAlert}
            success="failure"
          />
        )}
      </div>
    </>
  );
};

export default AvatarSignUp;
