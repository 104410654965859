import React from "react";

import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SocketProvider } from "./context/SocketProvider";
import { LoginProvider } from "./context/LoginProvider";
import { ConnectionProvider } from "./context/SoulmateProvider";
import { EmbeddingsProvider } from "./context/EmbeddingsProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="51556420934-io2bl3n6nssagldn7fotn0odfspeg5mj.apps.googleusercontent.com">
      <EmbeddingsProvider>
        <LoginProvider>
          <SocketProvider>
            <ConnectionProvider>
              {" "}
              <App />
            </ConnectionProvider>
          </SocketProvider>
        </LoginProvider>
      </EmbeddingsProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
