const API_URL = "https://soulmegle.com/api/api/auth";
// const API_URL = "http://localhost:9000/api/auth";

export const login = async (email, password) => {
  const response = await fetch(`${API_URL}/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  });

  if (response.ok) {
    const data = await response.json();

    return data;
  } else {
    const txt = await response.text();
    throw new Error(txt);
  }
};

export const signup = async (name, email, password, guestUsername) => {
  const response = await fetch(`${API_URL}/register`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ name, email, password, guestUsername }),
  });

  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    const txt = await response.text();
    throw new Error(txt);
  }
};
export const signInWithGoogle = async (response) => {
  try {
    const data = await fetch(`${API_URL}/google`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: response.credential }),
    });
    if (data.ok) {
      const res = await data.json();

      return res.user;
    } else {
      throw await data.text();
    }
  } catch (error) {
    throw error;
  }
};
export const continueWithGoogle = async (response) => {
  try {
    const data = await fetch(`${API_URL}/google-login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: response }),
    });
    if (data.ok) {
      const res = await data.json();

      return res.user;
    } else {
      throw await data.text();
    }
  } catch (error) {
    throw error;
  }
};
export const requestOTPfromServer = async (email) => {
  const response = await fetch(`${API_URL}/request-otp`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  });

  if (response.ok) {
    const responseText = await response.text();

    return responseText;
  } else {
    const responseText = await response.text();

    throw new Error(responseText);
  }
};

export const resetPasswordtoServer = async ({ email, otp, newPassword }) => {
  const response = await fetch(`${API_URL}/reset-password`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, otp, newPassword }),
  });

  if (response.ok) {
    const responseText = await response.text();

    return responseText;
  } else {
    const responseText = await response.text();

    throw new Error(responseText);
  }
};
