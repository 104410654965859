// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/singupImage.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-image {
  flex: 1 1;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center;
}
@media (max-width: 768px) {
  .signup-image {
    display: none;
  }
  .width-100vw-sm {
    width: 100vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Authentication/SignupPage.css"],"names":[],"mappings":"AAAA;EACE,SAAO;EACP,mDAA+C;EAC/C,sBAAsB;EACtB,2BAA2B;AAC7B;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,YAAY;EACd;AACF","sourcesContent":[".signup-image {\n  flex: 1;\n  background: url(\"../../assets/singupImage.png\");\n  background-size: cover;\n  background-position: center;\n}\n@media (max-width: 768px) {\n  .signup-image {\n    display: none;\n  }\n  .width-100vw-sm {\n    width: 100vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
